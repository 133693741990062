<template>
  <div class="elderlyCareAppointmentDetail">
    <div class="section-warpper" v-if="!!detail">
      <div class="section-header">
        <v-swiper
          :autoplay="2000"
          :swiperData="detail.roomPic ? detail.roomPic.split(',') : []"
        >
        </v-swiper>
        <!-- <img src="./img/banner.png" alt="" /> -->
        <!-- <img :src="detail.picture" alt="" /> -->
      </div>

      <div class="section-title">
        {{ `${detail.roomName}-${detail.bedName}` }}
      </div>
      <div class="section-detail">
        <div class="detail-item" v-for="(key, index) in keys" :key="index">
          <div class="detail-item--name">
            {{ key | keyName }}
          </div>
          <div class="detail-item--value">
            {{ keyValue(key) }}{{ key | keyUnit }}
          </div>
        </div>
        <div class="detail-item">
          <div class="detail-item--name">备注</div>
          <div class="detail-item--value">护理费根据老人的评估等级而定</div>
        </div>
      </div>

      <div class="surebtn" v-if="detail.rentalStatus == '0'">
        <div class="btn" :style="detail.rentalStatus | btnCss" @click="submit">
          {{ detail.rentalStatus | btnTxt }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getVoteResultURL, bedDetailByIdURL } from "./api";
import { statusMap, btnStatusMap } from "./map";

export default {
  data() {
    return {
      id: "",
      detail: {
        roomName: "",
        bedName: "",
        rentalStatus: "",
        monthlyRent: "",
        roomOrientation: "",
        floorNumber: "",
        contactsName: "",
        contactsMobile: "",
        roomType: "",
        roomArea: "",
      },
      // detail: null,
      isClick: true,
      keys: [
        "rentalStatus",
        "monthlyRent",
        "roomOrientation",
        "floorNumber",
        "contactsName",
        "contactsMobile",
        "roomType",
        "roomArea",
      ],
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  filters: {
    btnTxt(status) {
      return btnStatusMap[status];
    },
    btnCss(status) {
      let btnStatusCSS = {
        0: {
          color: "#FFFFFF",
          background: "#138347",
        },
        // 1: {
        //   color: "#DA9797",
        //   background: "#FCF2F3",
        // },
        // 2: {
        //   color: "#FFFFFF",
        //   background: "#D93747",
        // },
      };
      return btnStatusCSS[status];
    },
    keyName(key) {
      const names = {
        rentalStatus: "出租状态",
        monthlyRent: "月单价",
        roomOrientation: "朝向",
        floorNumber: "楼层",
        contactsName: "联系人",
        contactsMobile: "联系电话",
        roomType: "房间类型",
        roomArea: "房间面积",
      };
      return names[key];
    },
    keyUnit(key) {
      const keys = {
        rentalStatus: "",
        monthlyRent: "/月",
        roomOrientation: "",
        floorNumber: "层",
        contactsName: "",
        contactsMobile: "",
        roomType: "",
        roomArea: "m²",
      };
      return keys[key];
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      const { id } = this.$route.query;
      this.id = id;
      this.getDetail();
    },
    async submit() {
      if (!this.isClick) {
        return;
      }
      this.isClick = false;
      // let params = {
      //   id: this.id,
      //   userId: this.userId,
      // };
      // let res = await this.$axios.get(getVoteResultURL, params);
      // if (res.code === 200) {
      //   // this.result = res.data;
      //   this.$toast({ message: "提交申请成功", duration: 2000 });
      // } else {
      //   this.isClick = true;
      // }
      this.$router.replace({
        name: "elderlyCareAppointmentApply",
        query: {
          id: this.detail.id,
          roomName: this.detail.roomName,
          bedName: this.detail.bedName,
        },
      });
    },
    async getDetail() {
      let params = {
        id: this.id,
        // userId: this.userId,
      };
      let res = await this.$axios.get(bedDetailByIdURL, { params: params });
      if (res.code === 200 && res.data) {
        this.detail = res.data;
      }
    },

    // 获取值
    keyValue(key) {
      if (key === "rentalStatus") {
        return statusMap[this.detail[key]];
      }
      return this.detail[key];
    },
  },
};
</script>

<style lang="less" scoped>
.elderlyCareAppointmentDetail {
  box-sizing: border-box;
  width: 100%;
  // background: #f6f8fb;
  font-family: PingFangSC-Regular, PingFang SC;
  padding: 20px 30px;
  padding-bottom: calc(
    40px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    40px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-warpper {
    min-height: calc(100vh - 60px - constant(safe-area-inset-bottom));
    min-height: calc(100vh - 60px - env(safe-area-inset-bottom));
    // background: #ffffff;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    // padding: 50px 36px 44px;
    // border-radius: 16px;
  }
  .section-header {
    margin-bottom: 40px;
    border-radius: 16px;
    width: 100%;
    height: 330px;
    overflow: hidden;
    // img {
    //   overflow: hidden;
    // }
  }
  .section-title {
    font-size: 52px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 72px;
    margin-bottom: 40px;
    white-space: normal;
    word-break: break-all;
    word-wrap: break-word;
    padding: 0 18px;
  }
  .section-detail {
    font-size: 48px;
    font-weight: 400;
    color: rgba(0, 0, 0);
    line-height: 84px;
    white-space: normal;
    word-break: break-all;
    word-wrap: break-word;
    text-align: left;
    padding: 0 18px;
    // font-weight: bold;
    .detail-item {
      &:not(:first-child) {
        margin-top: 10px;
      }
      display: flex;
      // align-items: center;
      .detail-item--name {
        width: 260px;
        height: 100%;
        min-width: 260px;
        margin-right: 44px;
      }
      .detail-item--value {
        flex: 1;
        line-height: 72px;
        color: rgba(0, 0, 0, 0.5);
        padding: 12px 0;
      }
    }
  }
  .surebtn {
    margin-top: 78px;
    width: 100%;
    // display: flex;
    // justify-content: center;
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 94px;
      // background: linear-gradient(270deg, #c4e2ff 0%, #9ccdff 100%);
      border-radius: 16px;
      // border: 8px solid #ecf7ff;
      font-weight: bold;
      font-size: 46px;
      color: #ffffff;
      letter-spacing: 4px;
    }
  }
}
</style>
